@import url("https://use.typekit.net/ycq2oex.css");
#backgroundColor {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: radial-gradient(92.3% 110.55% at 78.3% 7.45%, #005F74 0%, #0E2A30 40.75%, #010210 100%);
  z-index: -100;
}

* {
  margin: 0;
  padding: 0;
}

#header {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 30px);
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  z-index: 100;
}
#header .headerLogo {
  display: inline-block;
  width: 124px;
  height: 29px;
  background-image: url("images/KikoMintsLogo.svg");
}
#header .headerOptions {
  width: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: "Univia-Pro";
  font-weight: 900;
}
#header .headerOptions a {
  font-size: 12px;
  position: relative;
  height: 24px;
  text-decoration: none;
  color: white;
  margin: 0 0 0 16px;
}
#header .headerOptions a:after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  border-radius: 999px;
  background-color: #E88934;
  transition: 200ms;
  transform: scale(0, 1);
  transform-origin: center;
}
#header .headerOptions a:hover {
  cursor: pointer;
}
#header .headerOptions a:hover::after {
  opacity: 1;
  transform: scale(1, 1);
}
#header .headerOptions a.textLink span {
  display: inline-block;
  padding-top: 2px;
}
#header .headerOptions a.iconLink {
  width: 24px;
  height: 24px;
  background-position: center center;
  background-size: contain;
}
#header .headerOptions a.iconLink.discord {
  background-image: url("images/Icon=Discord.svg");
}
#header .headerOptions a.iconLink.twitter {
  background-image: url("images/Icon=Twitter.svg");
}

.App {
  position: absolute;
  top: 0;
  font-family: museo-sans-condensed, sans-serif;
  text-align: center;
  width: 100vw;
  height: 100vh;
  min-height: 500px;
  gap: 0%;
  color: #00D2FF;
}

.backgroundShop {
  position: absolute;
  top: 0;
  left: -5vw;
  width: 60vw;
  height: 90vh;
  background: url("shop.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: auto 100%;
  z-index: 1;
}

.content {
  position: absolute;
  top: 0;
  right: 10vw;
  width: 33vw;
  height: 100vh;
  min-height: 100%;
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  z-index: 2;
  text-align: left;
  overflow: visible;
}

h1 {
  font-family: museo-sans-rounded;
  font-weight: 1000;
  font-size: 64px;
  background: radial-gradient(105.76% 90.65% at 50% 12.08%, #FFEA9E 0%, #DD5D00 100%), #E6822B;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-transform: uppercase;
  text-align: left;
  line-height: 91.2%;
  overflow: visible;
  margin-right: -50px;
  margin-bottom: 10px;
}

h2 {
  font-family: museo-sans-rounded;
  font-weight: 1000;
  font-size: 32px;
  background: radial-gradient(105.76% 90.65% at 50% 12.08%, #FFEA9E 0%, #DD5D00 100%), #E6822B;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-transform: uppercase;
  text-align: left;
  line-height: 91.2%;
  overflow: visible;
  margin: 0px -50px 10px 0;
}

button {
  border: none;
  position: relative;
  background: radial-gradient(73.22% 101.05% at 42.25% 0%, #02586B 0%, #02586B 38.54%, #091922 100%);
  border-radius: 100px;
  color: black;
  font-family: museo-sans-rounded;
  font-weight: bold;
  margin: 36px 0 36px 20px;
  height: 44px;
  width: 260px;
  padding-left: 24px;
}
button:disabled:hover {
  background: radial-gradient(73.22% 101.05% at 42.25% 0%, #02586B 0%, #02586B 38.54%, #091922 100%);
}
button:hover {
  background: radial-gradient(71.43% 131.25% at 42.25% 0%, #0088A6 0%, #02586B 46.35%, #002A42 100%);
  cursor: pointer;
}
button:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -2px;
  border-radius: inherit;
  background: radial-gradient(73.22% 101.05% at 42.25% 0%, #FFECA8 0%, #DD5D00 100%);
}
button:after {
  content: "";
  position: absolute;
  top: -16px;
  left: -38px;
  z-index: 2;
  width: 97px;
  height: 80px;
  background-image: url("images/cookie.png");
  background-size: contain;
}
button .text {
  font-size: 20px;
  background: radial-gradient(105.76% 90.65% at 50% 12.08%, #FFEA9E 0%, #DD5D00 100%), #E6822B;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.mintButton {
  position: relative;
}
.mintButton .stock {
  font-size: 14px;
  position: absolute;
  bottom: 14px;
  left: 106px;
}

p {
  font-size: 16px;
}

h3 {
  font-family: museo-sans-rounded;
  font-weight: 1000;
  font-size: 16px;
}

.message {
  margin: 20px 0;
}

.twitter-share-button {
  margin-top: 16px;
}

.options {
  display: flex;
  align-items: end;
  gap: 8px;
}

.small-button {
  display: inline-flex;
  height: 28px;
  padding: 0px 12px 0px 36px;
  border-radius: 9999px;
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 18px 18px;
  margin-top: 0px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color: white;
  text-decoration: none;
  font-size: 13px;
  font-family: "Helvetica Neue";
  font-weight: bold;
}
.small-button.discord {
  background-color: #404eed;
  background-image: url("images/Icon=Discord.svg");
}
.small-button.discord:hover {
  background-color: #2F3CD8;
}
.small-button.opensea {
  background-color: #2081E2;
  background-image: url("images/Icon=OpenSea.svg");
}
.small-button.opensea:hover {
  background-color: #1868B7;
}
.small-button.looksrare {
  background-color: #04CD58;
  background-image: url("images/Icon=LooksRare.svg");
}
.small-button.looksrare:hover {
  background-color: #10AF52;
}

i.emoji {
  display: inline-block;
  width: 1.25em;
  height: 1em;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  transform: scale(1.25);
}
i.emoji.kikoshrug {
  background-image: url("images/emoji-kikoshrug.svg");
}

@media only screen and (max-width: 800px) {
  #backgroundColor {
    height: 140vh;
  }

  .backgroundShop {
    position: relative;
    width: 100vw;
    left: 0vw;
    height: 100vw;
    background-position: center bottom;
  }

  .content {
    position: relative;
    width: 80vw;
    height: auto;
    min-height: auto;
    left: 10vw;
    justify-content: flex-start;
    padding-bottom: 100px;
  }
}

